<template>
  <div class="index">
    <div class="index-lunbo">
      <img src="../../static/img/index/indexlunbo1.png" alt="" />
      <div class="index-lunbo-icon">
        <img src="../../static/img/index/indexlunboicon.png" alt="" />
      </div>
    </div>
    <div class="index-title">
      NEWS
      <div class="index-title-small">资&nbsp;讯</div>
    </div>
    <div class="index-news">
      <ul class="index-news-list">
        <li v-for="item in news">
          <div class="index-news-list-title">{{ item.date }}</div>
          <div class="index-news-list-content">{{ item.content }}</div>
        </li>
      </ul>
      <div class="index-news-more">点击查看更多</div>
    </div>
    <div class="index-title">
      Services
      <div class="index-title-small">服&nbsp;务</div>
    </div>
    <div class="index-service">
      <div class="index-service-left">
        <div class="left-icon">
          <img src="../../static/img/index/indexserviceicon.png" alt="" />
        </div>
        <div class="left-num">{{ num[pre - 1] }}</div>
        <div class="left-title">{{ title[pre - 1] }}</div>
        <div class="left-content">{{ content[pre - 1] }}</div>
        <div class="left-tubiao">
          <img v-if="pre === 1" v-for="item in icon1" :src="item" alt="" />
          <img v-if="pre === 2" v-for="item in icon2" :src="item" alt="" />
          <img v-if="pre === 3" v-for="item in icon3" :src="item" alt="" />
        </div>
        <div class="left-ios">{{ ios[pre - 1] }}</div>
        <div class="left-small">服务详情</div>
        <!-- <div class="left-qie">
          <div class="left-qie-previous">
            <img src="../../static/img/index/previous.png" alt="" />
          </div>
          <div class="left-qie-next">
            <img src="../../static/img/index/next.png" alt="" />
          </div>
        </div> -->
      </div>
      <div class="index-service-right">
        <el-carousel
          :interval="5000"
          arrow="never"
          height="500px"
          :autoplay="true"
          indicator-position="outside"
          style="border-radius: 16px"
          @change="change"
        >
          <el-carousel-item v-for="item in lunbo" :key="item.id">
            <img :src="item.img" alt="" />
          </el-carousel-item>
        </el-carousel>
        <!-- <img src="../../static/img/index/indexservice.png" alt="" /> -->
      </div>
    </div>
    <div class="index-title">
      product
      <div class="index-title-small">产&nbsp;品</div>
    </div>
    <div class="index-product">
      <div class="product-left">
        <div class="product-left-smalltitle">趣 聊</div>
        <div class="product-left-title">让通讯更简单</div>
        <div class="product-left-content">
          即时通讯产品简介： 支持创建工作组的功能，
          工作组中允许最大200人同时进行聊天或发布消息,，会员创建群和加入群的权限，
          完全可以由工作组管理员进行控制，
          支持创建临时语音讨论组功能，同时进行即时通讯和视频会议。
        </div>
        <div class="product-lfet-mi">
          <div class="product-left-mi-content">
            <div class="mi-title">
              <div class="mi-icon">
                <img src="../../static/img/index/indexproductleft.png" alt="" />
              </div>
              <div class="mi-wenzi">聊天内容加密</div>
            </div>
            <div class="mi-content">用密钥对聊天数据进行加密，聊天更安全。</div>
          </div>
          <div class="product-left-mi-content">
            <div class="mi-title">
              <div class="mi-icon">
                <img
                  src="../../static/img/index/indexproductright.png"
                  alt=""
                />
              </div>
              <div class="mi-wenzi">多端消息同步</div>
            </div>
            <div class="mi-content">随时随地爽快收发，轻松你的工作和生活。</div>
          </div>
        </div>
        <div class="product-left-button" @click="toproduct()">下载App</div>
      </div>
      <div class="product-right">
        <img src="../../static/img/index/indexproduct.png" alt="" />
      </div>
    </div>
    <div class="index-title">
      ABOUT
      <div class="index-title-small" style="margin-left: -80px">关于我们</div>
    </div>
    <div class="index-about">
      <div class="index-about-top">
        <div class="index-about-top-left">
          <div class="about-top-left-title">与时俱进 共同发展</div>
          <div class="about-top-left-small">
            忠于品质 为你而生 / BE LOYAL TO QUALITY
          </div>
          <div class="about-top-left-content">
            秉持“专业、进取、实效、共赢”的理念，拥有专家级智库、实战经验丰富的团队和企业价值提升的完善服务体系，与市场、研究机构及各类企业服务机构建立广泛合作关系。通过商业模式、资本运营和人力资源创新，为中小企业进行人才及产业链全方位赋能服务，助力企业破局重生。
          </div>
          <div class="about-top-left-more">
            <img
              src="../../static/img/index/indexaboutmore.png"
              alt=""
              @click="toabout()"
            />
          </div>
        </div>
        <div class="index-about-top-right">
          <img style="width: 100%" src="../../static/img/index/indexabout.jpg" alt="" />
        </div>
      </div>

      <!-- <div class="index-about-bottom">
      <ul class="index-about-list">
        <li>
          <div class="about-bottom-num">1000+</div>
          <div class="about-bottom-zi">服务客户</div>
        </li>
        <li>
          <div class="about-bottom-num">300+</div>
          <div class="about-bottom-zi">合作企业</div>
        </li>
        <li>
          <div class="about-bottom-num">50+</div>
          <div class="about-bottom-zi">资深专家</div>
        </li>
        <li>
          <div class="about-bottom-num">10+</div>
          <div class="about-bottom-zi">行业经验</div>
        </li>
      </ul>
      <div class="index-about-xian">
        <div class="xian active"></div>
        <div class="xian"></div>
        <div class="xian"></div>
        <div class="xian"></div>
      </div>
    </div> -->
    </div>
    <!-- <div class="index-title">
    PARTER
    <div class="index-title-small" style="margin-left: -80px;">合作伙伴</div>
  </div>
  <div class="index-parter">
    <div class="index-parter-left">
      <img src="../../static/img/index/indexparter1.png" alt="">
    </div>
    <div class="index-parter-right">
      <div class="parter-right-title">
        <span class="num">300+</span>
        <span class="wenzi">企业的选择</span>
      </div>
      <div class="parter-right-content">
        我们期待着能与各界合作伙伴共同努力，携手同行，以价值为引领，用开拓和探索的精神，共创数字科技的美好未来。
      </div>
      <div class="parter-right-xian"></div>
      <div class="parter-right-button">详情咨询</div>
      <div class="parter-right-right">
        <img src="../../static/img/index/indexparter2.png" alt="">
      </div>
    </div>
  </div> -->
  </div>
</template>

<script>
import routeScroll from "../../utils/routeScroll";
export default {
  name: "index",
  data() {
    return {
      news: [
        { date: "2022/07/22", content: "万字对比AR/VR：讲真的，谁更有未来？" },
        {
          date: "2022/05/28",
          content: "以“锁库”实例解构：从需求分析到方案确定",
        },
        { date: "2022/04/30", content: "四步走，做有深度的数据分析" },
        {
          date: "2022/03/15",
          content: "这个简单的营销技巧，帮苹果公司狂赚了几十亿!",
        },
        {
          date: "2022/03/08",
          content: "困在物流里的抖音电商，能被“极速达”解救吗？",
        },
        {
          date: "2022/02/25",
          content: "字节推出“头条号外”App，抖音爱奇艺宣布达成合作",
        },
      ],
      lunbo: [
        { id: 1, img: require("../../static/img/index/indexservice2.png") },
        { id: 2, img: require("../../static/img/index/indexservice3.png") },
        { id: 3, img: require("../../static/img/index/indexservice4.jpg") },
      ],
      pre: 1,
      num: ["01", "02", "03"],
      title: ["网站建设", "客服系统", "微信小程序"],
      content: [
        "个性化策划、设计、程序开发等全方位服务，打造高品质网站",
        "集网站、微信公众号、App客服系统等功能于一体的客服系统",
        "跨行业场景化解决方案实践，我们是专业的解决方案提供商！",
      ],
      icon1: [
        require("../../static/img/index/indexservicediv.png"),
        require("../../static/img/index/indexservicewin.png"),
        require("../../static/img/index/indexserviceios.png"),
        require("../../static/img/index/indexservicean.png"),
      ],
      icon2: [
        require("../../static/img/index/indexservicediv.png"),
        require("../../static/img/index/indexservicewin.png"),
        require("../../static/img/index/indexserviceios.png"),
        require("../../static/img/index/indexservicean.png"),
        require("../../static/img/index/indexserviceshare.png"),
      ],
      icon3: [require("../../static/img/index/indexserviceshare.png")],
      ios: ["Windows/macOS", "Windows/macOS/App", "Mini Apps"],
    };
  },
  methods: {
    change(pre, next) {
      this.pre = pre + 1;
      console.log(this.pre);
    },
    toproduct() {
      routeScroll().then(() => {
        this.$router.push({
          path: "/product/product",
        });
      });
    },
    toabout() {
      routeScroll().then(()=>{
        this.$router.push({
        path: "/about/about",
      });
      })
      
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  .index-lunbo {
    width: 100%;
    // height: 48vw;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .index-lunbo-icon {
      width: 46px;
      height: 58px;
      position: absolute;
      left: 50%;
      margin-left: -23px;
      bottom: 12px;
    }
  }
  .index-title {
    font-size: 88px;
    font-family: DINPro-Bold, DINPro;
    font-weight: bold;
    color: #e8edf8;
    margin: auto;
    margin-top: 155px;
    text-align: center;
    position: relative;
    .index-title-small {
      position: absolute;
      left: 50%;
      margin-left: -44px;
      top: 45px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-size: 44px;
      font-weight: 600;
      color: #252b3a;
    }
  }
  .index-news {
    width: 1260px;
    height: 400px;
    margin: auto;
    margin-top: 150px;
    .index-news-list {
      width: 1260px;
      height: 321px;
      li {
        list-style: none;
        border-bottom: 1px solid #3070ef;
        float: left;
        width: 600px;
        height: 96px;
        margin-bottom: 20px;
        margin: 0px 14px;
        cursor: pointer;
        .index-news-list-title {
          font-size: 14px;
          margin-top: 23px;
          font-family: DINPro-Bold, DINPro;
          font-weight: 600;
          color: #c9d6ef;
        }
        .index-news-list-content {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #252b3a;
          line-height: 50px;
        }
      }
      li:hover {
        background-color: #3070ef;
        div {
          color: white;
          padding-left: 41px;
        }
      }
    }
    .index-news-more {
      font-size: 16px;
      font-weight: 400;
      color: #3070ef;
      margin: auto;
      text-align: center;
      cursor: pointer;
    }
  }
  .index-service {
    width: 1260px;
    margin: auto;
    margin-top: 120px;
    height: 720px;
    display: flex;
    justify-content: space-between;
    .index-service-left {
      border-radius: 16px;
      height: 680px;
      width: 350px;
      .left-icon {
        width: 52px;
        height: 40px;
      }
      .left-num {
        font-size: 88px;
        font-weight: 800;
        color: #252b3a;
      }
      .left-title {
        font-size: 54px;
        font-weight: 500;
        color: #252b3a;
        padding-top: 52px;
      }
      .left-content {
        font-size: 24px;
        font-weight: 600;
        color: #575d6c;
        padding-top: 24px;
        line-height: 40px;
      }
      .left-tubiao {
        padding-top: 93px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 17px;
        }
      }
      .left-ios {
        font-size: 24px;
        font-weight: 500;
        color: #252b3a;
        padding-top: 17px;
      }
      .left-small {
        font-size: 14px;
        font-weight: 400;
        color: #a9adb6;
        padding-top: 17px;
      }
      .left-qie {
        margin-top: 26px;
        div {
          width: 32px;
          height: 32px;
          border-radius: 2px;
          border: 1px solid #f0f4fb;
          float: left;
          margin-right: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .left-qie-next {
          background-color: #0081ff;
        }
      }
    }
    .index-service-right {
      width: 816px;
      height: 510px;
      border-radius: 16px;
      .el-carousel__container {
        .el-carousel__item {
          width: 816px;
          border-radius: 16px;
        }
      }
      /deep/.el-carousel__button {
        width: 146px;
        height: 4px;
        border-radius: 8px;
        background: #3a8ee6;
      }

      .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
      }

      .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
      }
    }
  }
  .index-product {
    width: 1260px;
    margin: auto;
    margin-top: 120px;
    height: 780px;
    display: flex;
    justify-content: space-between;
    .product-left {
      width: 654px;
      height: 670px;
      margin-top: 107px;
      .product-left-smalltitle {
        font-size: 20px;
        color: #0081ff;
        font-weight: 600;
      }
      .product-left-title {
        font-size: 44px;
        font-weight: 600;
        padding-top: 22px;
        color: #252b3a;
      }
      .product-left-content {
        padding-top: 32px;
        font-size: 14px;
        font-weight: 400;
        color: #252b3a;
        line-height: 28px;
      }
      .product-lfet-mi {
        margin-top: 80px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .product-left-mi-content {
          width: 280px;
          .mi-title {
            .mi-icon {
              width: 24px;
              height: 24px;
              float: left;
            }
            .mi-wenzi {
              font-size: 24px;
              font-weight: 600;
              float: left;
              color: #252b3a;
              padding-left: 12px;
            }
          }
          .mi-content {
            font-size: 14px;
            font-weight: 400;
            color: #575d6c;
            line-height: 28px;
            padding-top: 50px;
          }
        }
      }
      .product-left-button {
        cursor: pointer;
        margin-top: 80px;
        width: 160px;
        height: 50px;
        background: linear-gradient(270deg, #3595ff 0%, #3481ff 100%);
        box-shadow: 0px 22px 44px 0px rgba(25, 83, 189, 0.05);
        border-radius: 25px;
        text-align: center;
        line-height: 50px;
        color: #fff;
      }
    }
    .product-right {
      width: 435px;
      height: 780px;
    }
  }
  .index-about {
    width: 1260px;
    height: 600px;
    margin: auto;
    margin-top: 90px;
    .index-about-top {
      width: 100%;
      height: 300px;
      display: flex;
      justify-content: space-between;
      .index-about-top-left {
        .about-top-left-title {
          font-size: 24px;
          font-weight: 600;
          color: #252b3a;
        }
        .about-top-left-small {
          font-size: 14px;
          font-weight: 400;
          color: #b6b8bd;
          padding-top: 15px;
        }
        .about-top-left-content {
          padding-top: 38px;
          width: 701px;
          font-size: 15px;
          font-weight: 400;
          color: #252b3a;
          line-height: 36px;
        }
        .about-top-left-more {
          cursor: pointer;
          width: 183px;
          height: 50px;
          margin-top: 46px;
        }
      }
      .index-about-top-right {
        width: 480px;
        height: 300px;
      }
    }

    .index-about-bottom {
      width: 1260px;
      height: 160px;
      margin-top: 140px;
      .index-about-list {
        padding: 0px;
        list-style: none;
        height: 100px;
        display: flex;
        justify-content: space-between;
        li {
          padding-left: 0px;
          list-style: none;
          text-align: center;
          .about-bottom-num {
            font-size: 64px;
            font-family: DINPro-Medium, DINPro;
            font-weight: 500;
            color: #0081ff;
            line-height: 64px;
          }
          .about-bottom-zi {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #252b3a;
            line-height: 30px;
          }
        }
      }
      .index-about-xian {
        margin-top: 30px;
        height: 4px;
        display: flex;
        justify-content: center;
        .xian {
          height: 4px;
          width: 315px;
          background-color: #d8d8d8;
        }

        .active {
          background-color: #1953bd;
        }
      }
    }
  }
  .index-parter {
    width: 1260px;
    margin: auto;
    margin-top: 124px;
    height: 700px;
    .index-parter-left {
      width: 593px;
      height: 547px;
      float: left;
    }
    .index-parter-right {
      margin-top: 143px;
      width: 441px;
      float: left;
      margin-left: 71px;
      position: relative;
      .parter-right-title {
        .num {
          font-size: 32px;
          font-weight: 800;
          color: #252b3a;
        }
        .wenzi {
          font-size: 24px;
          font-weight: 600;
          color: #252b3a;
        }
      }
      .parter-right-content {
        font-size: 14px;
        font-weight: 400;
        color: #575d6c;
        line-height: 28px;
        margin-top: 24px;
      }
      .parter-right-xian {
        width: 16px;
        height: 4px;
        background: #0081ff;
        border-radius: 2px;
        margin-top: 56px;
      }
      .parter-right-button {
        width: 122px;
        height: 42px;
        background: #e5f2ff;
        border-radius: 2px;
        cursor: pointer;
        margin-top: 32px;
        color: #0081ff;
        text-align: center;
        font-weight: 600;
        line-height: 42px;
      }
      .parter-right-right {
        width: 602px;
        height: 543px;
        position: absolute;
        left: 440px;
        top: 0;
      }
    }
  }
}
</style>
